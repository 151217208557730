import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import { useIcon, useMui, useComponent } from "../../hooks/Hooks";
import { useMui, useIcon, useOther  } from "../../hooks/Hooks";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import NoDataImg from "./../../assets/no-data.svg";
import axios from "../../services/axios";
import FileCard from "../../components/FileCard/FileCard";

import CustomModal, {
  DeleteModal,
} from "../../components/CustomModal/CustomModal";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  getCaseDetails,
  getAllCaseList,
  useAddCaseNote,
  getAllCaseNotes,
  useUpdateCase,
  getDocument,
  useDeleteDocument,
} from "../../hooks/useCaseHook";
import dayjs from "dayjs";
import { Formik, Form, Field } from "formik";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const CasesDetails = () => {
  let caseId = useParams();

  const {
    Divider,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Visibility,
  } = useMui();
  const { Pencil, ShieldX, Bookmark, CalendarCheck2, X } = useIcon();
  // const { CustomModal, CustomHeading } = useComponent();

  const [value, setValue] = React.useState(0);

  const [showForm, setShowForm] = React.useState(false);
  const [note, setNote] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [currentTitle, setCurrentTitle] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [dragActive, setDragActive] = React.useState(false);
  const [AddDocumentModal, setAddDocumentModal] = React.useState(false);
  const { mutate, isLoading } = useAddCaseNote();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdateCase();
  const { mutate: mutateDeleteDocument, isLoading: isLoadingDocument } =
    useDeleteDocument();

  const AllCaseDetails = useQuery("AllCaseDetails", () => {
    return getCaseDetails(caseId);
  });

  const AllCaseLists = useQuery("AllCaseLists", () => {
    return getAllCaseList(caseId);
  });

  const AllCaseNotes = useQuery("AllCaseNotes", () => {
    return getAllCaseNotes(caseId);
  });

  const DocumentData = useQuery("document-details", () =>
    getDocument(caseId.caseId)
  );

  const handlePencilClick = (title, index) => {
    setCurrentTitle(title);
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmitTitle = (values) => {
    console.log("New Title:", values.title);
    // setShowModal(false);

    let data = [values.title, caseId.caseId];

    // console.log(data);

    mutateUpdate(data, {
      onSuccess: (data) => {
        if (data.data === "Case Updated Successfully") {
          AllCaseDetails.refetch();
          // notify("Case Note is added.");
          setShowModal(false);
        }
      },
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddNote = () => {
    setShowForm(true);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === "dragenter" || e.type === "dragover");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleFormSubmit = (values, { resetForm }) => {
    // setNotes((prevNotes) => [...prevNotes, values.note]);
    // console.log(values)

    let data = [values, caseId];
    mutate(data, {
      onSuccess: (data) => {
        if (data.data === "Case Note Added Successfully") {
          AllCaseNotes.refetch();
          // notify("Case Note is added.");
          setShowForm(false);
          resetForm();
        }
      },
    });
  };

  console.log(caseId.caseId);

  const handleSubmit = async (e) => {
    console.log("HIii");
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file);
    formData.append("DateTime", dayjs().format("YYYY-MM-DD HH:mm:ss"));
    formData.append("cases_id", caseId.caseId);

    try {
      const response = await axios.post("/case/add-cases-document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.message === "Assignment Document Added Successfully") {
        // queryClient.invalidateQueries("document-details-" + caseId);
        setAddDocumentModal(false);
        DocumentData.refetch();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleViewNote = (note) => {
    setSelectedNote(note);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteDocument = (data) => {
    mutateDeleteDocument(data, {
      onSuccess: (response) => {
        // console.log(response, 'response');
        if (response.data?.message === "Document Deleted Successfully") {
          // queryClient.invalidateQueries('document-details-' + assignmentId);
        } else {
          console.log("Something went wrong");
          // notify('Something went wrong', 'error');
        }
      },
    });
  };

  const extractFirstWords = (htmlString, wordLimit) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const textContent = doc.body.textContent || '';
  
    // Split the text content into words, take the first wordLimit words, and join them
    const words = textContent.split(/\s+/).slice(0, wordLimit);
    const trimmedText = words.join(' ');
  
    // Add "..." if there are more words than the limit
    return words.length >= wordLimit ? `${trimmedText}...` : trimmedText;
  };

  return (
    <Container style={{ padding: "20px 0" }}>
      {!AllCaseDetails.isLoading &&
        AllCaseDetails.data.data.map((data, i) => (
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Card className="mb-3 custom-card">
                <Card.Body>
                  <div className="d-flex">
                    <h4 style={{ paddingRight: "15px" }}>{data.c_title}</h4>
                    <Pencil
                      style={{ color: "#253559", cursor: "pointer" }}
                      onClick={() => handlePencilClick(data.c_title, i)}
                    />
                  </div>
                  <h6>{data.c_name}</h6>
                </Card.Body>
              </Card>
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                style={{ zIndex: 9999 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Formik
                    initialValues={{ title: currentTitle }}
                    onSubmit={handleFormSubmitTitle}
                  >
                    {() => (
                      <Form>
                        <div className="form-group">
                          <Field
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <Button
                          type="submit"
                          variant="contained"
                          loading={isLoading}
                          color="primary"
                          className="mt-3 mb-3 "
                          isLoading={isLoadingUpdate}
                        >
                          Save Changes
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Card className="mb-3 custom-card">
                <Card.Body>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <div className="d-flex">
                        <ShieldX style={{ color: "253559" }} />
                        <p style={{ paddingLeft: "5px" }}>Case No.</p>
                      </div>
                      <p>{data.case_number}</p>
                    </Col>
                    <Col
                      style={{ marginBottom: "5.5rem" }}
                      xs={12}
                      md={6}
                      lg={6}
                    >
                      <div className="d-flex">
                        <Bookmark style={{ color: "253559" }} />
                        <p style={{ paddingLeft: "5px" }}>Stage</p>
                      </div>
                      <p>N.A.</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3} lg={3}>
              <Card className="mb-3 custom-card">
                <Card.Body style={{ textAlign: "center" }}>
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <CalendarCheck2
                      style={{ color: "253559", fontSize: "22px" }}
                    />
                    <p style={{ paddingLeft: "6px" }}>Next Hearing</p>
                  </div>
                  <h6>{dayjs(data.next_hearing_date).format("DD-MM-YYYY")}</h6>
                </Card.Body>
                <Divider sx={{ backgroundColor: "#ccc", height: "2px" }} />
                <Card.Body style={{ textAlign: "center" }}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div>
                      <h6>Court</h6>
                      {data.next_hearing_room !== "" ? (
                        <>
                          <p>{data.next_hearing_room}</p>
                        </>
                      ) : (
                        <>
                          <p>-</p>
                        </>
                      )}
                    </div>
                    <div>
                      <h6>Item No.</h6>
                      {data.next_hearing_item !== "" ? (
                        <>
                          <p>{data.next_hearing_item}</p>
                        </>
                      ) : (
                        <>
                          <p>-</p>
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <div sx={{ width: "100%" }}>
                <div sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Listing" {...a11yProps(0)} />
                    <Tab label="Docs" {...a11yProps(1)} />
                    <Tab label="Notes" {...a11yProps(2)} />
                    {/* <Tab label="Order" {...a11yProps(3)} /> */}
                  </Tabs>
                </div>
                <CustomTabPanel value={value} index={0}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>DATE</TableCell>
                          <TableCell align="right">TYPE</TableCell>
                          <TableCell align="right">COURT</TableCell>
                          <TableCell align="right">ITEM</TableCell>
                          {/* <TableCell align="right">STAGE</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!AllCaseLists.isLoading &&
                          AllCaseLists.data.data.map((row, i) => (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {dayjs(row.next_hearing_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </TableCell>
                              <TableCell align="right">
                                User Generated
                              </TableCell>
                              <TableCell align="right">
                                {row.next_hearing_room}
                              </TableCell>
                              <TableCell align="right">
                                {row.next_hearing_item}
                              </TableCell>
                              {/* <TableCell align="right"></TableCell> */}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Card className="custom-card my-3">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <Card.Title>All Document</Card.Title>
                      <Button onClick={() => setAddDocumentModal(true)}>
                        Upload Document
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      {DocumentData.data?.data?.length > 0 ? (
                        <div className="d-flex flex-wrap justify-content-start p-2">
                          {DocumentData.data?.data?.map((file) => (
                            <FileCard
                              key={file.doc_id}
                              file={file}
                              onDelete={() => handleDeleteDocument(file)}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="text-center">
                          <img src={NoDataImg} alt="No data" className="mb-5" />
                          <h2>
                            No records found. click on Add to create
                          </h2>
                        </div>
                      )}
                    </Card.Body>
                    {/* Add Document Modal */}
                    <CustomModal
                      open={AddDocumentModal}
                      handleClose={() => setAddDocumentModal(false)}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <h2>Add a New Document</h2>
                        <X onClick={() => setAddDocumentModal(false)} />
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="my-3">
                          <label>File Name :</label>
                          <input
                            type="text"
                            className="custom-input"
                            placeholder="Enter File Name"
                            value={title}
                            onChange={handleTitleChange}
                            required
                          />
                        </div>
                        <label>Document File :</label>
                        <div
                          onDragEnter={handleDrag}
                          onDragOver={handleDrag}
                          onDragLeave={handleDrag}
                          onDrop={handleDrop}
                          style={{
                            border: "2px dashed #ccc",
                            padding: "20px",
                            textAlign: "center",
                            backgroundColor: dragActive ? "#f9f9f9" : "#fff",
                          }}
                        >
                          <input
                            type="file"
                            name="document_file"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            id={`file-upload-document-file`}
                          />
                          <label
                            htmlFor={`file-upload-document-file`}
                            style={{ cursor: "pointer" }}
                          >
                            {file
                              ? file.name
                              : "Drag and drop a file or click to upload"}
                          </label>
                          <label>File:</label>
                        </div>
                        <Button type="submit" className="mt-3">
                          Upload
                        </Button>
                      </form>
                    </CustomModal>
                  </Card>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddNote}
                      className="mt-3 mb-3"
                    >
                      Add Note
                    </Button>

                    {showForm && (
                      <Formik
                        initialValues={{ note: "" }}
                        onSubmit={handleFormSubmit}
                      >
                        {({ setFieldValue }) => (
                          <Form className="text-center">
                            <Field name="note">
                              {({ field }) => (
                                <ReactQuill
                                  value={field.value}
                                  onChange={(value) =>
                                    setFieldValue("note", value)
                                  }
                                  theme="snow"
                                />
                              )}
                            </Field>
                            <Button
                              type="submit"
                              variant="contained"
                              loading={isLoading}
                              color="primary"
                              className="mt-3 mb-3"
                            >
                              Submit
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    )}

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="center">Note Title</TableCell>
                            <TableCell align="center">Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!AllCaseNotes.isLoading &&
                            AllCaseNotes.data.data.map((row, i) => (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {dayjs(row.create_dt).format("DD-MM-YYYY")}
                                </TableCell>
                                <TableCell align="center">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: extractFirstWords(row.notes, 8),
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() => handleViewNote(row.notes)}
                                  >
                                    <Visibility />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle>View Note</DialogTitle>
                      <DialogContent>
                        <div
                          dangerouslySetInnerHTML={{ __html: selectedNote }}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </CustomTabPanel>
              </div>
            </Col>
          </Row>
        ))}
    </Container>
  );
};

export default CasesDetails;
