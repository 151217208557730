import axios from "../services/axios";
import dayjs from "dayjs";
import { useMutation } from "react-query";

const DateTime = dayjs().format("YYYY-MM-DD HH:mm:ss");


export const getAllCase = async () => {
  try {
    // Send a POST request to the server to get lead source counting data
    const response = await axios.post(`/case/get-all-case`);
    // Return the response from the server
    return response;
  } catch (err) {
    // Return an error object in case of failure
    return err;
  }
};

export const getCaseDetails = async (data) => {
  // console.log(data);
  try {
    const response = await axios.post(`/case/get-case-detail`, {
      data,
    });
    // Return the response from the server
    return response;
  } catch (err) {
    // Return an error object in case of failure
    return err;
  }
};

export const getAllCaseList = async (data) => {
  // console.log(data);
  try {
    const response = await axios.post(`/case/get-all-case-list`, {
      data,
    });
    // Return the response from the server
    return response;
  } catch (err) {
    // Return an error object in case of failure
    return err;
  }
};

export const getAllCaseNotes = async (data) => {
  // console.log(data);
  try {
    const response = await axios.post(`/case/get-all-case-notes`, {
      data,
    });
    // Return the response from the server
    return response;
  } catch (err) {
    // Return an error object in case of failure
    return err;
  }
};

export const getCaseHearingDate = async () => {
  try {
    // Send a POST request to the server to get lead source counting data
    const response = await axios.post(`/case/get-all-case`);
    // Return the response from the server
    return response;
  } catch (err) {
    // Return an error object in case of failure
    return err;
  }
};

const SetCase = async (data) => {
  // console.log(data);
  try {
    const response = await axios.post(`/case/add-case`, {
      data,
    });
    return response;
  } catch (err) {
    return err;
  }
};

const SetCaseNote = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`/case/add-case-note`, {
      data,
    });
    return response;
  } catch (err) {
    return err;
  }
};

const UpdateCaseNote = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`/case/edit-case-note`, {
      data,
    });
    return response;
  } catch (err) {
    return err;
  }
};

const SetCaseDate = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`/case/add-case-hearing-date`, {
      data,
    });
    return response;
  } catch (err) {
    return err;
  }
};

const SetDeleteCase = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`/case/delete-case`, {
        data,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getDocument = async (data) => {
  try {
      const response = await axios.post(`/case/get-document`, { data });
      return response;
  } catch (err) {
      return err;
  }
};

const setDeleteDocument = async (data) => {
  console.log(data);
  try {
      const response = await axios.post(`/case/delete-document`, { data });
      return response;
  } catch (err) {
      return err;
  }
};

export const useAddCase = () => {
  return useMutation(SetCase);
};
export const useAddCaseNote = () => {
  return useMutation(SetCaseNote);
};
export const useUpdateCase = () => {
  return useMutation(UpdateCaseNote);
};
export const useDateCase = () => {
  return useMutation(SetCaseDate);
};
export const useDeleteCase = () => {
  return useMutation(SetDeleteCase);
};
export const useDeleteDocument = () => {
  return useMutation(setDeleteDocument);
};


