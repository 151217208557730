import React from "react";
import {
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import timelineData from "../../json/cirp-timeline.json";
import CIRPHeader from "../../components/CIRPHeader";
import { Card } from "react-bootstrap";
import CIRPProcess from "../../tabs/CIRPProcess";
import DebtorsProfile from "../../tabs/DebtorsProfile";
import { useQuery } from "react-query";
import useCIRPHook from "../../hooks/useCIRPHook";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useCaseSummaryHook from "../../hooks/CIRP/useCaseSummaryHook";

const CirpSummaryPage = () => {
  const {useCaseSummaryTimelineHook,getCaseSummaryTimelineHook} = useCaseSummaryHook();
  const { getCIRPDetails } = useCIRPHook();
  const { id } = useParams();

  const [tabValue, setTabValue] = React.useState(0);
  const [calculatedTimeline, setCalculatedTimeline] = React.useState([]);
  const [openDatePickerIndex, setOpenDatePickerIndex] = React.useState(-1);
  const [selectedDates, setSelectedDates] = React.useState([]);
  const [initialDates, setInitialDates] = React.useState([]);
  const [updatingData, setUpdatingData] = React.useState({});

  const {mutate, isLoading} = useCaseSummaryTimelineHook();

  const CirpProcessDetails = useQuery("CIRPProcessDetails-" + id, () => {
    return getCIRPDetails(id);
  });

  const UpdatedCaseSummaryTimeline = useQuery("UpdatedCaseSummaryTimeline-" + id, () => {
    return getCaseSummaryTimelineHook(id);
  });

  React.useEffect(() => {
    if (CirpProcessDetails?.data?.[0]) {
      const { order_date, receipt_date } = CirpProcessDetails.data[0];
      const calculateDates = () =>
        timelineData.map((item) => ({
          ...item,
          dueDateOrder: dayjs(order_date)
            .add(item.offset, "day")
            .format("DD/MM/YYYY"),
          dueDateReceiptOrder: dayjs(receipt_date)
            .add(item.offset, "day")
            .format("DD/MM/YYYY"),
        }));

      setCalculatedTimeline(calculateDates());
      const dates = Array(timelineData.length).fill(null);
      setSelectedDates(dates);
      setInitialDates([...dates]);
    }
  }, [CirpProcessDetails.data]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDateClick = (index) => {
    setOpenDatePickerIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const handleDateChange = (index, date) => {
    const updatedDates = [...selectedDates];
    updatedDates[index] = date;
    setSelectedDates(updatedDates);
    // Immediately update the display for all rows with the selected date
    setUpdatingData({
      ...updatingData,
      [index]: date.format("DD/MM/YYYY"),
    });
  };

  const handleSave = () => {
    // Compare the current state with the initial state to find changes
    const changes = {};
    selectedDates.forEach((date, index) => {
      if (date && date.format("DD/MM/YYYY") !== initialDates[index]?.format("DD/MM/YYYY")) {
        changes[index] = date.format("DD/MM/YYYY");
      }
    });
    mutate({changes, cirp_id: id},{
      onSuccess: () => {
        console.log("Data updated successfully");
      },
      onError: (error) => {
        console.error("Error updating data:", error);
      }
    });
    console.log("Changed Data:", changes);
  };

  const handleClose = () => {
    // Logic for closing the view
    console.log("View closed");
  };

  if(UpdatedCaseSummaryTimeline.isLoading){
    return (
      <p>Loading timeline...</p>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card className="custom-card">
        <Card.Header>
          <CIRPHeader />
        </Card.Header>
      </Card>
      <Card className="custom-card mt-3">
        <Card.Body>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="CIRP Timeline" />
            <Tab label="Debtors Profile" />
            <Tab label="CIRP Process" />
          </Tabs>

          {tabValue === 0 && (
            <>
              {CirpProcessDetails.isLoading ? (
                <p>Loading timeline...</p>
              ) : CirpProcessDetails.isError ? (
                <p>Error loading CIRP details</p>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell>Due Date (Order)</TableCell>
                          <TableCell>Due Date (Receipt of Order)</TableCell>
                          <TableCell>Custom Timeline</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {calculatedTimeline.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.action}</TableCell>
                            <TableCell>{row.dueDateOrder}</TableCell>
                            <TableCell>{row.dueDateReceiptOrder}</TableCell>
                            <TableCell>
                              <Box>
                                {openDatePickerIndex === index ? (
                                  <DatePicker
                                    value={selectedDates[index]}
                                    onChange={(date) => handleDateChange(index, date)}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                  />
                                ) : (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleDateClick(index)}
                                  >
                                    {JSON.parse(UpdatedCaseSummaryTimeline?.data[0]?.timeline)[index] || "Select Date"}
                                  </Button>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          )}
        </Card.Body>
        <Card.Footer style={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose} disabled={isLoading}>
            Close
          </Button>
        </Card.Footer>
      </Card>
      {tabValue === 1 && <DebtorsProfile caseID={id} />}
      {tabValue === 2 && <CIRPProcess caseID={id} />}
    </LocalizationProvider>
  );
};

export default CirpSummaryPage;
