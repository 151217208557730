import React from "react";
import "./../styles/FormUtils.css";
import { useBootstrap, useOther } from "../hooks/Hooks";

const FormUtils = () => {
  const { Row, Col } = useBootstrap();
  const { Field, ErrorMessage } = useOther();

  const CustomFormGroup = (props) => {
    return (
      <Col
        xs={props.xs === undefined ? 12 : props.xs}
        md={props.md === undefined ? 6 : props.md}
        className="mb-3"
        style={props.style}
      >
        <Row className="align-items-center">
          <Col xs={12} md={4}>
            <h3 className="custom-form-label">
              {props.formlabel}
              <span className="required-label">{props.star}</span>
            </h3>
          </Col>
          <Col xs={12} md={8}>
            {props.FormField}
          </Col>
        </Row>
      </Col>
    );
  };

  const CustomInputField = (props) => {
    return (
      <div style={{ width: props.InputWidth }}>
        <Field
          type={props.type}
          name={props.name}
          className="custom-form-input"
          placeholder={props.placeholder}
          disabled={props.disabled}
          style={{
            background: props.disabled && "#f2f2f2",
            cursor: props.disabled && "not-allowed",
          }}
          {...props}
        />
        <ErrorMessage name={props.name} component="div" className="error" />
      </div>
    );
  };

  const CustomOutlineInput = (props) => {
    const { Field, ErrorMessage } = useOther();
  
    return (
      <div className="custom-outline-input-group" style={{display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        marginBottom: '10px'}}>
        <label>{props.label}</label>
        <Field
          type={props.type}
          name={props.name}
          label={props.label}
          className="custom-outline-input"
          placeholder={props.placeholder}
          disabled={props.disabled}
          style={{
            background: props.disabled && "#f2f2f2",
            cursor: props.disabled && "not-allowed",
            textTransform: props.textTransform
              ? props.textTransform
              : "capitalize",
          }}
        />
        <ErrorMessage name={props.name} component="div" className="error" />
      </div>
    );
  };

  return { CustomInputField, CustomFormGroup, CustomOutlineInput };
};

export default FormUtils;
