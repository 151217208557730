import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import FormUtils from "../../utils/FormUtils";
import Button from "@mui/material/Button";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import "./CustomModal.css";
// import { CustomHeading } from "../Common/Common";
import { useBootstrap, useMui, useOther } from "../../hooks/Hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const CustomModal = ({ open, handleClose, ModalWidth, FormData, ...props }) => {
  //   const [open, setOpen] = React.useState(false);
  const { Modal } = useMui();
  const { Formik, Form } = useOther();
  const { Row, Col } = useBootstrap();

  const { CustomInputField, CustomFormGroup , CustomOutlineInput } = FormUtils();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: ModalWidth ? ModalWidth : 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: "5px",
          overflow: "scroll",
          maxHeight: "80vh",
        }}>
          {FormData ? (
            <Formik
              initialValues={FormData.initialValues}
              validationSchema={FormData.LawyerFormSchema}
              onSubmit={FormData.HandleSubmit}
            >
              <Form>
              <h2>Add new case</h2>
                <Row className="my-4">
                  {FormData.FormInputList.map((row, index) => (
                    <Col md={12} key={index}>
                      <CustomOutlineInput
                        name={row.name}
                        type={row.type}
                        label={row.label}
                      />
                    </Col>
                  ))}
                </Row>

                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    variant="contained"
                    className="cancel-button"
                    type="Button"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="submit-button"
                    type="submit"
                  >
                    {FormData.isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Form>
            </Formik>
          ) : props.children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;

export const DeleteModal = (props) => {
  const { Modal } = useMui();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.show}
      onClose={props.onHide}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.show}>
        <Box sx={style}>
          <div className="text-center mb-4">
            <ReportProblemOutlinedIcon sx={{ fontSize: 80, color: "#d32f2f" }} />
            <h3 className="my-4">Are you sure?</h3>
            <p className="pb-3">
              Do you really want to delete these records? This process cannot be
              undone.
            </p>
          </div>
          <div className="text-center d-flex justify-content-around pb-3">
            <Button
              variant="contained"
              sx={{ bgcolor: "#484343" }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              loading={props.loading}
              onClick={props.onclick}
            >
              {props.loading ? "Deleting..." : "Delete"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
