import React, { useState } from 'react';
import './FileCard.css';

const FileCard = ({ file, onDelete,  }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const getIcon = (type) => {
    switch (type) {
      case 'application/pdf':
        return '📄'; // Replace with actual PDF icon
      case 'image/jpeg':
      case 'image/png':
        return '🖼️'; // Replace with actual image icon
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '📝'; // Replace with actual DOC icon
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '📊'; // Replace with actual XLS icon
      case 'text/plain':
        return '📄'; // Replace with actual TXT icon
      default:
        return '📄'; // Default icon
    }
  };

  const truncateName = (name, maxLength = 20) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    }
    return name;
  };

  const handleMenuToggle = () => {
    setMenuVisible(!menuVisible);
  };

  const handleDelete = () => {
    onDelete(file.assignment_document_id);
  };

  return (
    <div className="file-card">
      <div className="file-icon">{getIcon(file.document_type)}</div>
      <div className="file-name" title={file.doc_file_name}>{truncateName(file.doc_file_name)}</div>
      <div className="menu-icon" onClick={handleMenuToggle}>⋮</div>
      {menuVisible && (
        <div className="menu">
          <div className="menu-item" onClick={handleDelete}>Delete</div>
        </div>
      )}
    </div>
  );
};

export default FileCard;
