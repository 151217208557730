import React from "react";
import { useMui, useIcon, useOther } from "../../hooks/Hooks";
import FormUtils from "../../utils/FormUtils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import CustomModal, {
  DeleteModal,
} from "../../components/CustomModal/CustomModal";
import { CaseHearingFormSchema } from "../../schema/CaseSchema";


import {
  getAllCase,
  useAddCase,
  useDeleteCase,
  useDateCase,
} from "../../hooks/useCaseHook";

import "./Lawyer.css";

const Cases = () => {
  const { IconButton, Tooltip, Button } = useMui();
  const { CustomInputField, CustomFormGroup  } = FormUtils();
  const { CalendarCheck2, Share2, Trash2, ChevronRight } = useIcon();
  const { Formik, Form, Yup, toast, useNavigate } = useOther();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [hearingID, setHearingID] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const { mutate, isLoading } = useAddCase();
  const { mutate: DeleteMutate, isLoading: DeleteisLoading } = useDeleteCase();
  const { mutate: HearingDateCase, AddisLoading } = useDateCase();

  const onClickHearingDate = (data) => {
    setHearingID(data);
    setShowAddModal(true);
  };

  const handleDeleteFun = (data) => {
    setShowDeleteModal(true);
    setHearingID(data);
  };

  const LawyerFormSchema = Yup.object().shape({
    hearing_court_name: Yup.string().required("Court Name is required"),
    case_title: Yup.string().required("Case Title is required"),
    case_type: Yup.string().required("Case Type is required"),
    case_number: Yup.string().required("Case Number is required"),
    case_year: Yup.string().required("Case Year is required"),
  });

  const notify = (msg) => {
    toast(msg, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const initialValues = {
    hearing_court_name: "",
    case_title: "",
    case_type: "",
    case_number: "",
    case_year: "",
  };

  const initialValuesDate = {
    c_uid: hearingID,
    next_hearing_date: "",
    next_hearing_room: "",
    next_hearing_item: "",
  };

  const AllCase = useQuery("AllCase", () => {
    return getAllCase();
  });

  const HandleSubmitDate = (data) => {
    // console.log(data);
    HearingDateCase(data, {
      onSuccess: (data) => {
        AllCase.refetch();
        setShowAddModal(false);
        notify("Next Hearing Date Updated");
        // setTimeout(() => {
        //   navigate("/case");
        // }, 1000);
      },
    });
  };

  const HandleSubmit = (data) => {
    mutate(data, {
      onSuccess: (data) => {
        if (data.data === "Case Added Successfully") {
          AllCase.refetch();
          notify("Case is added.");
          setModal(false);
        }
      },
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCases = AllCase.data?.data?.filter((item) => {
    const caseInfo = `${item.c_type} ${item.case_number} / ${item.case_year}`;
    return caseInfo.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const FormData = {
    initialValues,
    LawyerFormSchema,
    HandleSubmit,
    FormInputList: [
      { name: "hearing_court_name", type: "text", label: "Hearing Court Name" },
      { name: "case_title", type: "text", label: "Case Title" },
      { name: "case_type", type: "text", label: "Case Type" },
      { name: "case_number", type: "text", label: "Case Number" },
      { name: "case_year", type: "text", label: "Case Year" },
    ],
    isSubmitting: isLoading,
  };

  if (AllCase.isLoading) {
    return <h4>Loading...</h4>;
  }

  const caseData = [
    {
      id: "P345654654654",
      date: { day: 26, month: "June" },
      type: "Property Cases",
      category: "Property",
    },
    {
      id: "A123456789012",
      date: { day: 12, month: "July" },
      type: "Criminal Cases",
      category: "Criminal",
    },
    {
      id: "B987654321098",
      date: { day: 30, month: "August" },
      type: "Civil Cases",
      category: "Civil",
    },
    {
      id: "C564738291017",
      date: { day: 15, month: "September" },
      type: "Family Cases",
      category: "Family",
    },
  ];

  return (
    <div style={{ padding: "20px 60px" }}>
      {/* <Breadcrumb pagename="All Case's" /> */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <input
            type="text"
            placeholder="Search Cases with name..."
            className="custom-input"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <Button
          variant="contained"
          className="add-button"
          onClick={() => setModal(true)}
        >
          Add Cases
        </Button>
      </div>
      <CustomModal
        open={modal}
        handleClose={() => setModal(false)}
        FormData={FormData}
      />
      <Row>
        {filteredCases.length === 0 ? (
          <h4>No Cases Found</h4>
        ) : (
          filteredCases.map((item, index) => (
            <Col key={index} xs={12} md={6} lg={6}>
              <Card className="mb-3 custom-card">
                <Card.Body style={{ display: "flex", alignItems: "center" }}>
                  <div className="custom-card-date">
                    {item.next_hearing_date !== null ? (
                      <>
                        <h1 className="hearing-date">
                          {dayjs(item.next_hearing_date).format("D")}
                        </h1>
                        <h5 className="hearing-date">
                          {dayjs(item.next_hearing_date).format("MMMM")}
                        </h5>
                      </>
                    ) : (
                      <>
                        <h1>-</h1>
                        <h5>-</h5>
                      </>
                    )}
                  </div>
                  <div
                    style={{ padding: "0 1rem", borderLeft: "1px solid #ccc" }}
                  >
                    <p
                      className="mb-1"
                      style={{ fontSize: "12px", textTransform: "capitalize" }}
                    >
                      {item.c_type +
                        " " +
                        item.case_number +
                        " / " +
                        item.case_year}
                    </p>
                    <h5 style={{ textTransform: "capitalize" }}>
                      {item.c_title}
                    </h5>
                    <h6 style={{ textTransform: "capitalize" }}>
                      {item.c_name}
                    </h6>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <Button
                          className="custom-case-icon"
                          sx={{ mr: "20px" }}
                          variant="outlined"
                          startIcon={<CalendarCheck2 />}
                          onClick={() => onClickHearingDate(item?.c_id)}  
                        >
                          Next Hearing Date
                        </Button>
                      </div>
                      <div>
                        <Tooltip title="Share">
                          <IconButton
                            className="custom-case-icon"
                            aria-label="Share Case"
                          >
                            <Share2 />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="Delete Case">
                          <IconButton
                            className="custom-case-icon"
                            aria-label="delete"
                            onClick={() => handleDeleteFun(item?.c_id)}
                          >
                            <Trash2 />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="Open Cases">
                          <IconButton
                            component={NavLink}
                            to={`/lawyer-details/${item.c_id}`}
                            className="custom-case-icon"
                          >
                            <ChevronRight />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
      <CustomModal
        open={showAddModal}
        // handleClose={() => setShowAddModal(false)}
        children={
          <Formik
            initialValues={initialValuesDate}
            validationSchema={CaseHearingFormSchema}
            onSubmit={HandleSubmitDate}
          >
            <Form>
              <Card className="">
                <Card.Body>
                  {/* <Divider /> */}
                  <Row className="mt-3 align-items-center">
                    <CustomInputField
                      type="hidden"
                      name="c_uid"
                      placeholder=""
                    />
                    <CustomFormGroup
                      md={12}
                      formlabel="Next Hearing Date"
                      FormField={
                        <CustomInputField
                          type="date"
                          name="next_hearing_date"
                          placeholder="Enter Case Year"
                        />
                      }
                    />
                    <CustomFormGroup
                      md={12}
                      formlabel="Next Hearing Court Room No"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="next_hearing_room"
                          placeholder="Optional"
                        />
                      }
                    />
                    <CustomFormGroup
                      md={12}
                      formlabel="Next Hearing Item No"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="next_hearing_item"
                          placeholder="Optional"
                        />
                      }
                    />
                  </Row>
                  <Row>
                    <Col className="mt-4 mb-2 text-center" md={6}>
                      <Button
                        onClick={() => setShowAddModal(false)}
                        className="custom-btn-color"
                        type="submit"
                        sx={{ mr: "20px" }}
                        variant="outlined"
                      >
                        Canel
                      </Button>
                    </Col>
                    <Col className="mt-4 mb-2 text-center" md={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        loading={AddisLoading}
                        className="custom-btn-color"
                        startIcon={<CalendarCheck2 />}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </Formik>
        }
      />
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        loading={DeleteisLoading}
        onclick={() => {
          DeleteMutate([hearingID], {
            onSuccess: (response) => {
              AllCase.refetch();
              setShowDeleteModal(false);
              notify("Case Deleted Successfully");
              setHearingID("");
            },
          });
        }}
      />
    </div>
  );
};

export default Cases;
