import React from "react";
import CIRPHeader from "../../components/CIRPHeader";
import { useBootstrap, useIcon, useOther } from "../../hooks/Hooks";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Divider,
} from "@mui/material";
import useCIRPStageHook from "../../hooks/useCIRPStageHook";
import ProgressBar from "react-bootstrap/ProgressBar";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import useCIRPMilestoneHook from "../../hooks/useCIRPMilestoneHook";
import { CustomHeading } from "../../utils/Common";
import useCIRPTaskHook from "../../hooks/useCIRPTaskHook";
import Cookies from "js-cookie";
import { Plus } from "lucide-react";

const CirpCaseRun = () => {
  const { Card } = useBootstrap();
  const { useQuery, useNavigate, useParams } = useOther();
  const { ChevronRight } = useIcon();
  const { id } = useParams();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);


  const { setAllCIRPStageHook } = useCIRPStageHook();
  const { setAllCIRPMilestoneHook } = useCIRPMilestoneHook();
  const { setAllCIRPTaskHook, useExtendCIRPTaskHook, getCIRPExtendTaskHook } =
    useCIRPTaskHook();

  const AllCIRPStage = useQuery("AllCIRPStage", () => {
    return setAllCIRPStageHook();
  });

  const AllCIRPTask = useQuery("AllCIRPTask" + id, () => {
    return setAllCIRPTaskHook(id);
  });

  const AllExtendCIRPTask = useQuery("AllExtendCIRPTask" + id, () => {
    return getCIRPExtendTaskHook(id);
  });

  const AllCIRPMilestone = useQuery("AllCIRPMilestone" + id, () => {
    return setAllCIRPMilestoneHook();
  });

  const stageDefaultIndex = url.searchParams.get("stageID") || 4;
  
  // const [stageDefaultIndex, setStageDefaultIndex] = React.useState(url.searchParams.get("stageID") || 4);
  const [milestoneDefaultIndex, setMilestoneDefaultIndex] = React.useState(Number(url.searchParams.get("milestoneID") || 0));
  const [createTask, setCreateTask] = React.useState(false);
  const [activeStageId, setActiveStageId] = React.useState(Number(stageDefaultIndex));
  
  const [formData, setFormData] = React.useState({
    created_by: Cookies.get("user_id"),
    cirp_id: id,
    task_label: "",
    task_desc: "",
    day_of_complete: "",
  });

  const { mutate, isLoading } = useExtendCIRPTaskHook();

  const updateURLWithStageAndMilestone = (currentStageId, milestoneIndex) => {
    // Get current values from URL if parameters are not passed
    const stageIDFromURL = params.get("stageID") || "0"; // Default to 0 if undefined
    const milestoneIDFromURL = params.get("milestoneID") || "0"; // Default to 0 if undefined

    // Use provided values or fall back to values from the URL
    const stageID =
      currentStageId !== undefined ? currentStageId : stageIDFromURL;
    const milestoneID =
      milestoneIndex !== undefined ? milestoneIndex : milestoneIDFromURL;

    // Update or add the parameters
    params.set("stageID", stageID);
    params.set("milestoneID", milestoneID);

    // Update the URL without reloading the page
    window.history.replaceState(
      null,
      "",
      `${url.pathname}?${params.toString()}`
    );
  };

  const truncateName = (name, maxLength = 30) => {
    return name.length > maxLength
      ? name.substring(0, maxLength) + "..."
      : name;
  };

  const handleNext = (currentStageId) => {
    const nextStage = AllCIRPStage.data.find(
      (stage) => stage.stage_id > currentStageId
    );
    if (nextStage) {
      updateURLWithStageAndMilestone(nextStage.stage_id, milestoneDefaultIndex);
      setActiveStageId(nextStage.stage_id);
    }
  };

  // Update state as form inputs change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = (currentStageId) => {
    const prevStage = AllCIRPStage.data.find(
      (stage) => stage.stage_id < currentStageId
    );
    if (prevStage) {
      setActiveStageId(prevStage.stage_id);
    }
  };

  const handleCreateTask = () => {
    mutate(formData, {
      onSuccess: (data) => {
        AllExtendCIRPTask.refetch();
        setCreateTask(false);
      },
      onError: (error) => {
        console.error("Error occurred while submitting the form:", error);
      },
    });
  };

  if (
    AllCIRPStage.isLoading ||
    AllCIRPMilestone.isLoading ||
    AllCIRPTask.isLoading ||
    AllExtendCIRPTask.isLoading
  ) {
    return <p>Loading...</p>;
  }

  if (activeStageId === null) {
    setActiveStageId(AllCIRPStage.data[0].stage_id);
  }

  const isExtendTaskStep =
    activeStageId === AllCIRPStage.data[AllCIRPStage.data.length - 1].stage_id;

  return (
    <>
      <Card className="custom-card">
        <Card.Body className="p-3">
          <CIRPHeader />
          <Divider />

          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Stepper
              activeStep={stageDefaultIndex ? AllCIRPStage.data.findIndex(
                (stage) => stage.stage_id === Number(stageDefaultIndex)
              ) : 0}
            >
              {AllCIRPStage.data.map((stage, index) => (
                <Step
                  key={index}
                >
                  <StepLabel>{stage.stage_name}</StepLabel>
                </Step>
              ))}
              <Step>
                <StepLabel>Extend Task</StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Card.Body>
      </Card>

      {AllCIRPMilestone.data.map(
        (milestone, milestoneIndex) =>
          activeStageId === milestone.milestone_stage && (
            <Accordion
              className=""
              defaultExpanded={milestoneIndex === Number(milestoneDefaultIndex)}
              key={milestoneIndex}
              onClick={() => {updateURLWithStageAndMilestone(activeStageId, milestoneIndex);setMilestoneDefaultIndex(milestoneIndex)}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id={`panel-header-${milestoneIndex}`}
              >
                {milestone.milestone}
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                {AllCIRPTask.data
                  .filter(
                    (task) => task.milestone_id === milestone.milestone_id
                  )
                  .map((task, taskIndex) => (
                    <Button
                      key={task.cirp_task_id}
                      onClick={() =>
                        navigate(
                          `/process/cirp/${id}/case-runs/${task.stage_id}/${task.milestone_id}/${task.cirp_task_id}`
                        )
                      }
                      className="case-milestone-container mb-2 p-4 d-flex align-items-center justify-content-between w-100"
                    >
                      <div className="d-flex align-items-center">
                        <span className="case-milestone-number">
                          {AllCIRPTask.data.findIndex(
                            (t) => t.cirp_task_id === task.cirp_task_id
                          ) + 1}
                          .
                        </span>
                        <CustomHeading
                          Heading={truncateName(task.task_label)}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <p
                          className="m-0"
                          style={{
                            color: "#333",
                            fontWeight: "700",
                            textTransform: "capitalize",
                          }}
                        >
                          {task.status ? task.status : "Not Started"}
                        </p>
                        <ProgressBar
                          now={task.process_status}
                          style={{ width: "15rem", marginLeft: "1rem" }}
                        />
                      </div>
                      <ChevronRight />
                    </Button>
                  ))}
              </AccordionDetails>
            </Accordion>
          )
      )}

      {isExtendTaskStep && (
        <div style={{ background: "#fff" }}>
          {AllExtendCIRPTask.data.length > 0 &&
            AllExtendCIRPTask.data.map((task, index) => (
              <Button
                key={task.ect_id}
                onClick={() =>
                  navigate(
                    `/process/cirp/${id}/case-runs/extend/${task.ect_id}`
                  )
                }
                className="case-milestone-container mb-2 p-4 d-flex align-items-center justify-content-between w-100"
              >
                <div className="d-flex align-items-center">
                  <span className="case-milestone-number">{index + 1}.</span>
                  <CustomHeading Heading={task.task_label} />
                </div>
                <div className="d-flex align-items-center">
                  <p
                    className="m-0"
                    style={{
                      color: "#333",
                      fontWeight: "700",
                      textTransform: "capitalize",
                    }}
                  >
                    {task.status ? task.status : "Not Started"}
                  </p>
                  <ProgressBar
                    now={task.process_status}
                    style={{ width: "15rem", marginLeft: "1rem" }}
                  />
                </div>
                <ChevronRight />
              </Button>
            ))}
          <Button
            style={{ background: "#fff" }}
            onClick={() => setCreateTask(!createTask)}
            className="case-milestone-container mb-2 p-4 d-flex align-items-center justify-content-center w-100"
          >
            <div className="d-flex align-items-center justify-content-center gap-3">
              <Plus color="#405189" />
              <CustomHeading
                Heading={"Click to create a new task for this case "}
              />
            </div>
          </Button>
        </div>
      )}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStageId === AllCIRPStage.data[0].stage_id}
          onClick={() => handleBack(activeStageId)}
          sx={{ mr: 1 }}
          variant="contained"
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="contained"
          onClick={() => handleNext(activeStageId)}
          disabled={
            activeStageId ===
            AllCIRPStage.data[AllCIRPStage.data.length - 1].stage_id +1
          }
        >
          {activeStageId ===
          AllCIRPStage.data[AllCIRPStage.data.length - 1].stage_id + 1
            ? "Finish"
            : "Next"}
        </Button>
      </Box>

      <Dialog
        open={createTask}
        onClose={() => setCreateTask(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Create new task"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            name="task_label"
            label="Task Label"
            type="text"
            fullWidth
            variant="standard"
            value={formData.task_label}
            onChange={handleInputChange}
          />
          <TextField
            rows={5}
            className="mt-3"
            name="task_desc"
            label="Task Description"
            type="text"
            fullWidth
            variant="standard"
            value={formData.task_desc}
            onChange={handleInputChange}
          />
          <TextField
            className="mt-3"
            required
            name="day_of_complete"
            label="Completed after creating case"
            type="number"
            fullWidth
            variant="standard"
            value={formData.day_of_complete}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateTask(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleCreateTask();
            }}
            autoFocus
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CirpCaseRun;
