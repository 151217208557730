import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Avatar } from "@mui/material";
import {
  LogOut,
  Bell,
  Moon,
  LayoutDashboard,
  WalletCards,
  Fence,
  Circle,
  ChevronDown,
  ChevronRight,
  Users,
  Fingerprint,
} from "lucide-react";
import DummyUser from "./../assets/dummy-user.jpeg";
import CompanyLogo from "./../assets/company-logo.png";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./../styles/Leftsidebar.css";
import { useOther } from "../hooks/Hooks";
import useAuthHook from "../hooks/useAuthHook";

const drawerWidth = 250;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "isDrawerOpen",
})(({ theme, isDrawerOpen }) => ({
  background: "#f5f5f5", // Changed to a softer background
  flexGrow: 1,
  padding: theme.spacing(3),
  height: "100vh",
  overflow: "auto",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(isDrawerOpen && {
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "isDrawerOpen",
})(({ theme, isDrawerOpen }) => ({
  backgroundColor: "#ffffff",
  color: theme.palette.text.primary,
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  borderBottom: "1px solid #e0e0e0",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isDrawerOpen && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const menuItems = [
  {
    name: "Dashboard",
    icon: <LayoutDashboard />,
    path: "/dashboard",
    subMenu: [],
  },
  {
    name: "Process",
    icon: <LayoutDashboard />,
    path: "/process",
    subMenu: [
      { name: "CIRP", icon: <Circle />, path: "/process/cirp" },
      { name: "LIQUIDATION", icon: <Circle />, path: "/process/liquidation" },
      { name: "PRE-PACK", icon: <Circle />, path: "/process/pre-pack" },
      { name: "FAST TRACK", icon: <Circle />, path: "/process/fast-track" },
      { name: "FRESH START", icon: <Circle />, path: "/process/fresh-start" },
      { name: "DRT", icon: <Circle />, path: "/process/drt" },
    ],
  },
  {
    name: "Dyanamics Process",
    icon: <Fence />,
    path: "/process",
    subMenu: [
      { name: "CIRP Stage", icon: <Circle />, path: "/dyanamics/cirp-stage" },
      {
        name: "CIRP Milestone",
        icon: <Circle />,
        path: "/dyanamics/cirp-milestone",
      },
      { name: "CIRP Task", icon: <Circle />, path: "/dyanamics/cirp-task" },
    ],
  },
  {
    name: "Cases",
    icon: <Users />,
    path: "/all-cases",
    subMenu: [],
  },
  {
    name: "People",
    icon: <Users />,
    path: "/all-people",
    subMenu: [],
  },
  {
    name: "Task",
    icon: <WalletCards />,
    path: "/task",
    subMenu: [],
  },
  {
    name: "Reports",
    icon: <WalletCards />,
    path: "/reports",
    subMenu: [],
  },
  {
    name: "Payments",
    icon: <WalletCards />,
    path: "/payments",
    subMenu: [],
  },
  {
    name: "Academic",
    icon: <WalletCards />,
    path: "/academic",
    subMenu: [],
  },
];

export default function Leftsidebar({ setLoading, setLoggedIn }) {
  const { Cookies, useNavigate } = useOther();
  const { useLogoutHook } = useAuthHook();

  const navigate = useNavigate();

  const { mutate, isLoading } = useLogoutHook();

  const theme = useTheme();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const [openSubMenu, setOpenSubMenu] = React.useState(null);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleSubMenuToggle = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const handleLogout = () => {
    mutate(
      { user_id: Cookies.get("user_id") },
      {
        onSuccess: (response) => {
          if (response.status === 200) {
            Cookies.remove("user_id");
            Cookies.remove("username");
            Cookies.remove("role");
            setLoading(true);
            setLoggedIn(false);
            navigate("/");
          } else {
            alert(response.message);
          }
        },
      }
    );
  };

  return (
    <Box sx={{ display: "flex" }} className="leftsidebar-container">
      <CssBaseline />
      <AppBar position="fixed" isDrawerOpen={isDrawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(isDrawerOpen && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            className="d-flex align-items-center justify-content-between"
            sx={{ flexGrow: 1 }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontWeight: 600, color: "#374ead" }}
            >
              {"Welcome, " + Cookies.get("username") + " 👋"}
            </Typography>
            <Box className="d-flex align-items-center gap-3">
              <IconButton onClick={handleLogout}>
                <LogOut size={24} color="#908b8a" />
              </IconButton>
              <IconButton>
                <Fingerprint size={24} color="#908b8a" />
              </IconButton>
              <IconButton>
                <Bell size={24} color="#908b8a" />
              </IconButton>
              <Avatar alt="Remy Sharp" src={DummyUser} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
      >
        <DrawerHeader className="p-2">
          <img src={CompanyLogo} alt="Company Logo" className="w-75" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List className="leftsidebar-list">
          {menuItems.map((item, index) => (
            <React.Fragment key={item.name}>
              <ListItem disablePadding className="leftsidebar-item-menu">
                <ListItemButton
                  className="leftsidebar-item"
                  component={item.subMenu.length > 0 ? "button" : Link}
                  to={item.subMenu.length > 0 ? null : item.path}
                  selected={location.pathname === item.path}
                  onClick={() => handleSubMenuToggle(index)}
                >
                  {item.icon}
                  <ListItemText primary={item.name} />
                  {item.subMenu.length > 0 && (
                    <>
                      {openSubMenu === index ? (
                        <ChevronDown />
                      ) : (
                        <ChevronRight />
                      )}
                    </>
                  )}
                </ListItemButton>
              </ListItem>
              {item.subMenu.length > 0 && openSubMenu === index && (
                <List className="leftsidebar-submenu-list">
                  {item.subMenu.map((subItem) => (
                    <ListItem key={subItem.name} disablePadding>
                      <ListItemButton
                        className="leftsidebar-item-submenu"
                        component={Link}
                        to={subItem.path}
                        selected={location.pathname === subItem.path}
                        sx={{ pl: 4 }}
                      >
                        {subItem.icon}
                        <ListItemText primary={subItem.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Main isDrawerOpen={isDrawerOpen}>
        <DrawerHeader />
        {/* <BreadcrumbsComponent /> */}
        <Outlet />
      </Main>
    </Box>
  );
}
