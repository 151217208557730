import React from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useOther, useBootstrap } from "../hooks/Hooks";
import { Card } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import useCIRPMilestoneHook from "../hooks/useCIRPMilestoneHook";
import { Formik, Field } from "formik";
import * as Yup from "yup";

const AddClaim = () => {
  const { id } = useParams();
  const { toast, useNavigate } = useOther();
  const { Row, Col, Card } = useBootstrap();

  const navigate = useNavigate();

  const { useSetAddAssignmentClaim } = useCIRPMilestoneHook();

  const { mutate: mutateClaim, isLoading: isLoadingClaim } = useSetAddAssignmentClaim();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const notify = (msg, type) => {
    type === "success" ? toast.success(msg) : toast.error(msg);
  };

  const initialValues = {
    claim_name: "",
    claim_email: "",
    claim_address: "",
    claim_type: "",
    claim_date: "",
    principal_amount_claim: "",
    interest_amount_claim: "",
    other_cost_claim: "",
    name_beneficiary: "",
    name_bank: "",
    type_account: "",
    account_number: "",
    branch: "",
    ifsc_code: "",
    type_of_security: "",
    date_of_creation: "",
    details_of_security: "",
    amount_of_security: "",
    ownership_and_title: "",
    third_party_guarantee: "",
    amount_of_guarantee: "",
  };

  const validationSchema = Yup.object().shape({
    claim_name: Yup.string().required("Name is required"),
    claim_email: Yup.string().email("Invalid email").required("Email is required"),
    claim_address: Yup.string().required("Address is required"),
    claim_type: Yup.string().required("Type is required"),
    claim_date: Yup.date().required("Date is required"),
    principal_amount_claim: Yup.number().required("Principal amount is required"),
    interest_amount_claim: Yup.number(),
    other_cost_claim: Yup.number(),
    name_beneficiary: Yup.string().required("Beneficiary name is required"),
    name_bank: Yup.string().required("Bank name is required"),
    type_account: Yup.string().required("Account type is required"),
    account_number: Yup.string().required("Account number is required"),
    branch: Yup.string().required("Branch is required"),
    ifsc_code: Yup.string().required("IFSC Code is required"),
    type_of_security: Yup.string().required("Type of Security is required"),
    date_of_creation: Yup.date().required("Date of Creation is required"),
    details_of_security: Yup.string().required("Details of Security is required"),
    amount_of_security: Yup.number().required("Amount of Security is required"),
    ownership_and_title: Yup.string().required("Ownership and Title is required"),
    third_party_guarantee: Yup.string().required("Third Party Guarantee is required"),
    amount_of_guarantee: Yup.number().required("Amount of Guarantee is required"),
  });

  const steps = [
    { label: "General Details", fields: [
      { type: "text", label: "Name of the claimant", name: "claim_name" },
      { type: "email", label: "Email ID of the claimant", name: "claim_email" },
      { type: "text", label: "Address of the claimant", name: "claim_address" },
      { type: "select", label: "Type of claimant", name: "claim_type", options: [
          { label: "Lender/Financial Creditor", value: "Lender/Financial Creditor" },
          { label: "Supplier", value: "Supplier" },
          { label: "Employee/Workman", value: "Employee/Workman" },
          { label: "Statutory dues (Government dues)", value: "Statutory dues (Government dues)" },
          { label: "Other Creditors", value: "Other Creditors" },
          { label: "Homebuyer", value: "Homebuyer" },
          { label: "Depositor", value: "Depositor" },
          { label: "Debenture Holder", value: "Debenture Holder" },
          { label: "None of the above", value: "None of the above" },
        ]
      },
      { type: "date", label: "Date of receipt", name: "claim_date" },
    ]},
    { label: "Claim Amount Details", fields: [
      { type: "number", label: "Principal amount claimed (A)", name: "principal_amount_claim" },
      { type: "number", label: "Interest amount claimed (B)", name: "interest_amount_claim" },
      { type: "number", label: "Other cost claimed (C)", name: "other_cost_claim" },
    ]},
    { label: "Bank Details", fields: [
      { type: "text", label: "Name of the Beneficiary", name: "name_beneficiary" },
      { type: "text", label: "Name of the Bank", name: "name_bank" },
      { type: "text", label: "Type of Account", name: "type_account" },
      { type: "number", label: "Account Number", name: "account_number" },
      { type: "text", label: "Branch", name: "branch" },
      { type: "text", label: "IFSC Code", name: "ifsc_code" },
    ]},
    { label: "Details of Security", fields: [
      { type: "text", label: "Type of Security", name: "type_of_security" },
      { type: "date", label: "Date of Creation", name: "date_of_creation" },
      { type: "text", label: "Details of Security", name: "details_of_security" },
      { type: "number", label: "Amount of Security", name: "amount_of_security" },
      { type: "text", label: "Ownership and Title", name: "ownership_and_title" },
      { type: "text", label: "Third Party Guarantee", name: "third_party_guarantee" },
      { type: "number", label: "Amount of Guarantee", name: "amount_of_guarantee" },
    ]},
  ];

  const HandleSubmit = (values) => {
    console.log(values);
    mutateClaim(
      { ...values, id },
      {
        onSuccess: (response) => {
          if (response.data === "Claim Added Successfully") {
            navigate("/process/cirp/" + id + "/claims");
          } else {
            notify("Something went wrong", "error");
          }
        },
      }
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Add Claim</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={HandleSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {step.fields.map((field, idx) => (
                        <Row key={idx} className="align-items-center mb-3">
                          <Col md={5}>
                            <Typography variant="body1" component="div">
                              <strong>{field.label}</strong>
                            </Typography>
                          </Col>
                          <Col md={7}>
                            {field.type === "select" ? (
                              <Field as="select" name={field.name} className="form-control">
                                {field.options.map((option, optionIdx) => (
                                  <option key={optionIdx} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            ) : (
                              <Field
                                type={field.type}
                                name={field.name}
                                className="form-control"
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                            type={index === steps.length - 1 ? "submit" : "button"}
                            disabled={isLoadingClaim}
                          >
                            {index === steps.length - 1 ? "Finish" : "Continue"}
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default AddClaim;
